













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTeam from '@improve/common-components/src/components/team/BaseTeam.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import Team, { TEAM_TYPE } from '@improve/common-utils/src/model/Team';
import { TeamMemberAction } from '@improve/common-utils/src/types/TeamMemberAction';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';

@Component({
  name: 'CreateTeam',
  components: {
    TabContent,
    BaseButton,
    BaseTeam
  }
})
export default class CreateTeam extends Vue {
  @Action createTeam!: ActionMethod;

  @Action processTeamSubscriptions!: ActionMethod;

  private team: Team | null = null;

  get actionTitle(): string {
    return this.$route.params.type === 'virtual'
      ? 'page.createTeam.addVirtualTeam'
      : 'page.createTeam.addUnit';
  }

  get baseTeam(): BaseTeam {
    return this.$refs.baseTeam as any;
  }

  created(): void {
    this.setTeam();
  }

  setTeam(): void {
    const type = this.$route.params.type === 'virtual'
      ? TEAM_TYPE.VIRTUAL_TEAM
      : TEAM_TYPE.ORGANIZATIONAL_TEAM;
    this.team = new Team(null, '', '', type);
  }

  async handleSubmit(): Promise<void> {
    if (!(await this.baseTeam.validate())) {
      return;
    }
    const payload: {
      team: Team | null;
      actions: Map<string, TeamMemberAction>;
    } = this.baseTeam.prepareTeam();

    const savedTeam: Team = await this.createTeam(payload.team);
    await this.processTeamSubscriptions({
      teamId: savedTeam.id,
      memberActions: payload.actions
    });
    this.goBack();
  }

  goBack(): void {
    this.$router.push({ name: 'Teams' });
  }
}
